import React from 'react';
import { Link } from "gatsby";

const JobCard = ({job}) => {
    return (
        <div className="col-lg-12 col-md-12">
            <div className="single-features">
                <div className="icon h2">
                    <a href={`/apply-for-job?jobid=${job.id}`}>
                        <i className="bx bx-chevron-right"></i>
                    </a>
                </div>
                <h3><Link to={`/apply-for-job?jobid=${job.id}`}>{job.title}</Link> <div className="badge bg-success">{job.rank_label}</div> </h3>
                <strong>Salary:</strong> {job.salary}
                <div className="row">
                    <div className="col-md-3">
                        <strong>Apply Before:</strong> {job.apply_before}
                    </div>
                    <div className="col-md-3">
                        <strong>Joining Date:</strong> {job.joining_date}
                    </div>
                    <div className="col-md-3">
                        <strong>Location:</strong> {job.location}
                    </div>
                    {job.positions > 1 && <div className="col-md-3">
                        <strong>Positions:</strong> {job.positions}
                    </div>}
                </div>

                <ul className="list-unstyled mt-2">
                {job.skills.length > 0 &&
                        job.skills.map((skill, index) => (
                        <li className="badge bg-primary">{skill}</li>
                        ))}
                </ul>
            </div>
        </div>
    )
}

export default JobCard