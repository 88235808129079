import React, { useState, useCallback, useEffect } from 'react';
import JobCard from "./Sections/JobCard";
 
const JobList = () => {
    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(true);

    const loadJobs = useCallback(async () => {
        const response = await fetch(`${process.env.GATSBY_API_URL}/career-opportunities?limit=1000`);
        const resResult = await response.json();
        if(typeof resResult.meta !== 'undefined' && resResult.meta.total > 0) {
            setJobs(resResult.data);
        }

        setLoading(false);
    }, []);

    useEffect(() => {
        loadJobs();
    }, [loadJobs]);

    if(loading) {
        return (
            <div className="d-flex justify-content-center m-5">
                <div className="spinner-grow text-success" style={{width: '5rem', height: '5rem'}} role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }

    return (
        <div className="contact-area ptb-80">
            <div className="container">

                <div className="row justify-content-center">
                    {jobs.length > 0 &&
                        jobs.map((job, index) => (
                            <JobCard job={job} />
                        ))}
                </div>

            </div>
        </div>
    )
}

export default JobList;