import React from "react";
import Layout from "../components/_App/layout";
import Seo from "../components/_App/seo";
import Footer from "../components/_App/Footer";
import PageBanner from '../components/Common/PageBanner';
import NavbarStyleSix from "../components/_App/NavbarStyleSix";
import JobList from "../components/Job/JobList";

const Contact = () => (
    <Layout>
        <Seo title="Career at Karigar" />

        <NavbarStyleSix />

        <PageBanner pageTitle="Career Opportunities" />

        <JobList />

        <Footer />
    </Layout>
)

export default Contact;